// import { saveSubContractorResponses } from './api';
import i18n from 'i18next';

export const ENTITY_STATUS = {
  EDITED: i18n.t('common.status_ongoing'),
  NOT_EDITED: i18n.t('common.new'),
  SUBMITTED: i18n.t('common.validation_in_progress'),
  REJECTED: i18n.t('common.awaiting_modification'),
  VALIDATED: i18n.t('common.validated'),
};

export const MODAL_TYPES = {
  ADD_NEW_ENTITY: 'ADD_NEW_ENTITY',
  ADD_USER: 'ADD_USER',
  AUTHORIZED_SURVEYS: 'AUTHORIZED_SURVEYS',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DISABLE_PRODUCT: 'DISABLE_PRODUCT',
  DUPLICATE_PRODUCT: 'DUPLICATE_PRODUCT',
  ERROR: 'ERROR',
  NEW_PRODUCT: 'NEW_PRODUCT',
  NEXT_UNFULFILLED_SURVEY: 'NEXT_UNFULFILLED_SURVEY',
  RENAME_PRODUCT: 'RENAME_PRODUCT',
  SUBMIT_ENTITY: 'SUBMIT_ENTITY',
  SUBMIT_ENTITY_CONFIRMATION: 'SUBMIT_ENTITY_CONFIRMATION',
  SUBMIT_ENTITY_ERRORS: 'SUBMIT_ENTITY_ERRORS',
  SHARE: 'SHARE',
};

export const ENTITY_STEPS_STATUS = {
  NOT_STARTED: i18n.t('common.not_started'),
  IN_PROGRESS: i18n.t('common.status_ongoing'),
  DONE: i18n.t('common.validated'),
};

/*export const ENTITY_STEPS: { label: string; name: string; status: string }[] = [
  {
    label: 'C’est le début de votre aventure Dipeeo !',
    name: 'step1',
    status: ENTITY_STEPS_STATUS.DONE,
  },
  {
    label: 'Décrivez nous votre activité',
    name: 'step2',
    status: ENTITY_STEPS_STATUS.DONE,
  },
  {
    label: 'Vérification de vos réponses par nos experts',
    name: 'step3',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Nous sommes officiellement votre DPO',
    name: 'step4',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Vos documents Registres/Inventaire PBD sont prêts',
    name: 'step5',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Documents Cookies/Politique/Mention RGPD sont prêts',
    name: 'step6',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Votre site est conforme !',
    name: 'step7',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Documents RH/Sécurité sont prêts',
    name: 'step8',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
  {
    label: 'Maintien opérationnel de votre structure',
    name: 'step9',
    status: ENTITY_STEPS_STATUS.NOT_STARTED,
  },
];*/

export const ENTITY_CONFORMITY_STEPS = [
  {
    label: i18n.t('common.survey'),
    name: 'survey',
    description: i18n.t('steps.survey_step_description'),
    status: {
      activatedAt: undefined,
      activatedBy: undefined,
    },
  },
  {
    label: i18n.t('common.dpo'),
    name: 'dpo',
    description: i18n.t('steps.dpo_step_description'),
    status: {
      activatedAt: undefined,
      activatedBy: undefined,
    },
  },
];

export const PROCESSOR_MONITORING_STATUS = [
  {
    label: i18n.t('common.compliant'),
    value: 'CONFORM',
    color: 'FF82D0A5',
  },
  {
    label: i18n.t('common.to_audit'),
    value: 'TO_BE_AUDITED',
    color: 'FF1179A0',
  },
  {
    label: i18n.t('common.document_to_sign'),
    value: 'TO_BE_SIGNED',
    color: 'FFFFA500',
  },
  {
    label: i18n.t('common.non_compliant'),
    value: 'NOT_CONFORM',
    color: 'FFDD0615',
  },
];

export const BUTTON_COLORS = {
  default: '#F49D1D',
  danger: '#ff7675',
  admin: '#a29bfe',
  success: '#00b894',
  adminHover: '#7f5ce7',
  dangerHover: '#ed3f3e',
  defaultHover: '#187ead',
  successHover: '#00916e',
  disabled: '#b2bec3',
  bright: '#fff',
};

export const COLORS = {
  lightNavy: '#0f3e60',
  NiceBlue: '#187ead',
  AquaMarine: '#49bfbd',
  AquaBlue: '#00d2e2',
  Black: '#3D3D3D',
  Squash: '#f49d1d',
  Red: '#dd0615',
  Green: '#82d0a5',
  LightBlue: '#DDF1F9',
  darkBlue: '#326C82',
  Grey: '#585858',
  LightGrey: '#EFF0F6',
  MediumGrey: '#C8C8C8',
  darkGrey: '#757575',
  LightOrange: '#FDEFDB',
  DarkGreen: '#00b894',
  DarkGrey: 'rgba(0, 0, 0, 0.8)',
  lightGrey: 'rgba(0, 0, 0, 0.6)',
  ultraLightGrey: 'rgba(0, 0, 0, 0.2)',
  softRed: '#ff7675',
  white: '#fff',
  xlsx: '#0B9D58',
  docx: '#6991d6',
  pdf: '#ff7675',
  admin: '#a29bfe',
  darkViolet: '#666297',
  ToasterError: '#E75555',
};

export const Z_INDEX = {
  below: -1,
  base: 0,
  above: 1,
  popover: 50,
  overlay: 100,
  sidenav: 200,
  modal: 600,
  loader: 800,
  overall: 999,
};

export const COUNTER_TYPES = [
  {
    label: i18n.t('counters.assistance_requests'),
    description: i18n.t('counters.assistance_requests_description'),
    amount: 0,
    id: 'assistance_requests',
  },
  {
    label: i18n.t('counters.user_rights'),
    description: i18n.t('counters.user_rights_description'),
    amount: 0,
    id: 'user_rights',
  },
  {
    label: i18n.t('counters.data_violation'),
    description: i18n.t('counters.data_violation_description'),
    amount: 0,
    id: 'data_violation',
  },
];

export const ACCOUNT_TYPES = {
  ADMIN: 'superadmin',
  AGENCY: 'agency',
  CLIENT: 'user',
};

export const NEW_LABELS = {
  VERSION: i18n.t('common.new_version'),
  ENTITY: i18n.t('common.new_entity'),
};

export const VERSION_INIT_LABEL = NEW_LABELS.VERSION;

export const USER_ROLES = {
  WIZARDS: 'superadmin',
  ADMIN: 'admin',
  AccountOfficer: 'accountOfficer',
  Guest: 'guest',
  Sales: 'sales',
};

export const ADMIN_ROLES = [USER_ROLES.ADMIN, USER_ROLES.AccountOfficer];
