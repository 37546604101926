import styled from 'styled-components';
import { COLORS } from '@utils/constants';

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

const TableButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;
  width: 100%;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const EntityTableContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 25px;
  user-select: none;

  > span {
    font-size: 26px;
    font-weight: 700;
    font-family: 'Nunito', serif;
  }

  svg {
    color: ${COLORS.darkBlue};
    opacity: 1;
  }
`;

const RowsSlider = styled.div`
  overflow-y: auto;
  flex: 1;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 10px;
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.darkBlue};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    padding-right: 10px;
  }
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
  width: calc(100% - 50px);
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(117, 117, 117, 0.1);
  overflow: hidden;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 25px 25px 5px 25px;
`;

const TableHeader = styled.div`
    display: flex;
    column-gap: 80px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: background-color 0.2s ease-in-out;
    width: calc(100% - 25px);
    padding-bottom: 15px;
    padding-right: 25px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 80px;
  height: 50px;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
  color: ${COLORS.Black};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Name = styled.span`
  font-family: 'Roboto', serif;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  column-gap: 5px;
  user-select: none;

  svg {
    color: ${COLORS.Squash};
    height: 24px;
    width: 24px;
  }
`;

const Header = styled.span`
  font-family: 'Roboto', serif;
  font-size: 1.167rem;
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  column-gap: 5px;
  user-select: none;
  color: ${COLORS.lightGrey};
`;

const Splitter = styled.div`
  flex: 1;
`;

const Data = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 1.167rem;
  font-family: 'Roboto', serif;
  font-weight: 500;
  position: relative;
  justify-content: center;
  width: 200px;

  span {
    white-space: nowrap;
  }

  div {
    font-size: 12px;
    color: ${COLORS.lightGrey};
    font-weight: 500;
    text-decoration: underline;
  }

  svg {
    color: ${COLORS.lightGrey};
  }

  @media (max-width: 1260px) {
    &.collaborators,
    &.completed-surveys {
      display: none;
    }
  }
`;

const DataHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  position: relative;
  justify-content: center;
  font-family: 'Roboto', serif;
  font-size: 1.167rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: ${COLORS.lightGrey};
  width: 200px;
`;

const MoreOptionsContainer = styled.div`
  width: 40px;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
  width: 100%;
  height: 100%;

  > span {
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 60%;
  }
`;

export {
  ButtonContainer,
  TableButtonContainer,
  BasicModalContainer,
  EntityTableContainer,
  Top,
  RowsSlider,
  RowsContainer,
  TableContainer,
  TableHeader,
  Row,
  Name,
  Header,
  Splitter,
  Data,
  DataHeader,
  MoreOptionsContainer,
  EmptyContainer,
};
