import { useState, useRef, useEffect, useCallback } from 'react';

import { BsSearch } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

import { Container, IconContainer, Input, InputContainer } from './SearchBar_.style';
import { SearchBarProps } from './SearchBar.type';
import { CONTAINER_TEST_ID, INPUT_CONTAINER_TEST_ID, INPUT_TEST_ID, CLEAR_ICON_TEST_ID } from './SearchBar.constant';

const SearchBar = ({ query, setQuery, placeholder, collapsible = false, disabled, size, colors }: SearchBarProps) => {
  const [barOpened, setBarOpened] = useState<boolean>(false);

  const inputFocus = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    event => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        if (barOpened) {
          setBarOpened(false);
        }
      }
    },
    [barOpened]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Container
      width={size?.width}
      height={size?.height}
      barOpened={barOpened}
      collapsible={collapsible}
      onClick={e => {
        setBarOpened(true);
        inputFocus?.current?.focus();
        handleClickOutside(e);
      }}
      onFocus={() => {
        setBarOpened(true);
        inputFocus?.current?.focus();
      }}
      ref={divRef}
      data-testid={CONTAINER_TEST_ID}
      backgroundColor={colors?.background}>
      <IconContainer barOpened={barOpened} collapsible={collapsible}>
        <BsSearch />
      </IconContainer>
      <InputContainer barOpened={barOpened} collapsible={collapsible} data-testid={INPUT_CONTAINER_TEST_ID}>
        <Input
          onChange={e => setQuery(e.target.value)}
          ref={inputFocus}
          value={query}
          barOpened={barOpened}
          placeholder={placeholder || 'Rechercher ...'}
          disabled={disabled}
          data-testid={INPUT_TEST_ID}
        />
        {query !== '' && (
          <AiOutlineClose
            onClick={() => {
              !disabled && setQuery('');
              !disabled && inputFocus?.current?.focus();
            }}
            data-testid={CLEAR_ICON_TEST_ID}
          />
        )}
      </InputContainer>
    </Container>
  );
};

export default SearchBar;
