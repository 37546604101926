import styled from 'styled-components';
import { COLORS } from '../../utils/constants';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';

export interface DataProps {
  icon: IconType;
  label: string;
  color?: string;
}

export interface ConformityCardProps {
  data: Array<DataProps>;
  button?: {
    onClick: () => void;
    label: string;
    disabled?: boolean;
  };
  percentage: number;
  status?: string;
}

export const ConformityCard = ({ data, percentage }: ConformityCardProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ConformityCardContainer>
        <Top>
          <span>{t('conformityCard.title')}</span>
        </Top>
        <Bottom>
          <Global>
            <Percentage>{percentage}%</Percentage>
            <span>{t('common.globalConformity')}</span>
          </Global>
          {data.map((item, index) => {
            let Icon = item.icon;
            return (
              <Data key={index}>
                <GradientIcon>
                  <Icon color={item.color} />
                </GradientIcon>
                <Label>{item.label}</Label>
              </Data>
            );
          })}
        </Bottom>
      </ConformityCardContainer>
      <GradientDefs />
    </>
  );
};

const GradientIcon = styled.div`
  svg {
    width: 30px;
    height: 30px;
    stroke: url(#gradient);
  }
`;

const ConformityCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  padding: 15px 30px;
  border-radius: 10px;
  border: 1px solid rgba(117, 117, 117, 0.1);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  justify-content: space-around;
  row-gap: 8px;
`;

const Global = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > span {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const Data = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  svg {
    width: 30px;
    height: 30px;
  }
`;

const Label = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-family: 'nunito', serif;
`;

const Top = styled.div`
  color: ${COLORS.darkGrey};
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 0.5px solid #d9d9d9;
  padding-bottom: 8px;
`;

const Bottom = styled.div`
  display: flex;
  column-gap: 20px;
  width: 100%;
  justify-content: space-between;
`;

const Percentage = styled.div`
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${COLORS.darkBlue};
  background: ${COLORS.LightBlue};
  border-radius: 5px;
  padding: 2px 10px;
`;

const GradientDefs = () => (
  <svg width='0' height='0' style={{position: 'absolute'}}>
    <defs>
      <linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
        <stop offset='0%' style={{ stopColor: '#326C82', stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: '#3BA9D3', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  </svg>
);
