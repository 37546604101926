import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { tippy } from '@tippyjs/react';
import Select from 'react-select';
import dayjs from 'dayjs';

import { AiOutlineDelete, AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';
import { RiShareForwardLine } from 'react-icons/ri';
import { BiHide, BiShow } from 'react-icons/bi';
import { MdCancel, MdCheck } from 'react-icons/md';

import Loader from '../loader/loader';
import MultiTextValueInput from './MultiTextInput';

import { setModal, setShareModal, selectCurrentEntity, selectCurrentVersion } from '../../reducers/app.reducer';
import { selectActiveAccount } from '../../reducers/account.reducer';

import { handleUploadFilesToS3, isURLValid } from '../../utils/utils';
import { deleteDocumentFromS3ByKey, getDocumentsFromS3ByKeyWithoutError } from '../../utils/api';
import { COLORS, MODAL_TYPES } from '../../utils/constants';

import 'tippy.js/dist/tippy.css';
import 'dayjs/locale/fr';
import { InputComponentProps } from './InputComponent.types';
import {
  DeleteDocument,
  DisabledList,
  FieldContainer,
  InfosContainer,
  InputLabel,
  InputStatus,
  LoaderContainer,
  PasswordContainer,
  RadioContainer,
  StyledHelpIcon,
  StyledInput,
  UploadContent,
  UploadError,
  UploadInputContainer,
  UploadItemContainer,
  UploadItemName,
  Option,
  Error,
  InputWrapper,
  IconContainer,
} from './InputComponent_.style';

dayjs.locale('fr');

export function InputComponent({
  className,
  inputKey,
  id,
  label,
  name,
  type,
  longAnswers,
  placeholder,
  options,
  value,
  onChange,
  tooltip,
  darkMode,
  bold,
  span,
  display,
  clearable,
  last,
  required,
  disabled,
  sharable = true,
  focus = false,
  onHover,
  onLeave,
  status = false,
  onKeyDown,
  acceptURL = true,
  acceptWhiteSpaces = true,
  onBlur,
  error,
  defaultValue,
  labelColor = COLORS.DarkGrey,
  noOptionsMessage,
  isSubQuestion,
  errorColor,
  externalUpload,
  locked,
  icon,
}: InputComponentProps) {
  const dispatch = useDispatch<any>();
  const inputRef = useRef(null);
  const shareRef = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const currentEntity = useSelector(selectCurrentEntity);
  const activeAccount = useSelector(selectActiveAccount);
  const currentVersion = useSelector(selectCurrentVersion);
  const deleteFileRef = useRef(null);

  const getMultiDefaultValue = value => {
    if (!value) {
      return [];
    }

    if (typeof value !== 'object') {
      return [
        {
          label: getOptionLabelByValue(value),
          value: value,
          name: value,
        },
      ];
    }

    return (
      value.map(value => ({
        label: getOptionLabelByValue(value),
        value: value,
        name: value,
      })) || []
    );
  };

  const getMultiValue = value => value.map(item => item.value);

  const getOptionLabelByValue = value => options?.find(options => options.value === value)?.label;

  const removeURLInValue = value => {
    return isURLValid(value) ? value.replace(/^(?:https?:\/\/)?(?:www\.)?/, '').split('.')[0] : value;
  };

  const shareItem = (id, label) => {
    dispatch(setShareModal({ type: 'questions', id, label }));
    dispatch(setModal({ show: true, type: MODAL_TYPES.SHARE }));
  };

  const isQuestionFilled = () => {
    return value && value.length > 0;
  };

  const toggleResetPassword = () => {
    setPasswordShown(!passwordShown);
  };

  // TODO : Should be removed if all stored input data are valid
  const handleTextInputValue = e => {
    let newValue = e.target?.value ?? e;
    if (!acceptURL) {
      newValue = removeURLInValue(newValue);
    }
    if (!acceptWhiteSpaces) {
      newValue = newValue.trim().replaceAll(' ', '');
    }
    return newValue;
  };

  useEffect(() => {
    focus && inputRef.current?.focus();
    tippy(shareRef.current, {
      content: 'Partager la question',
      arrow: true,
      placement: 'right',
    });

    tippy(deleteFileRef.current, {
      content: 'Supprimer le document',
      arrow: true,
      placement: 'left',
    });

    if (!value && value !== '') return;

    if (type === 'upload') {
      setFileLoading(true);
      (async () => {
        //Try to find file on new path
        let file = await getDocumentsFromS3ByKeyWithoutError(
          `public/${activeAccount?._id}/${currentEntity?._id}/user-uploads/${currentVersion?._id}/${name}/${value.replaceAll(' ', '_')}`
        );
        //Try on old path
        if (!file.Body) {
          file = await getDocumentsFromS3ByKeyWithoutError(
            externalUpload
              ? `public/${externalUpload.accountId}/${externalUpload.entityId}/${externalUpload.path}/${value.replaceAll(' ', '_')}`
              : `public/${activeAccount._id}/${currentEntity._id}/user-uploads/${name}/${value.replaceAll(' ', '_')}`
          );
        }
        //Remove file from responses if not fount on s3
        if (!file.Body) {
          onChange({ target: { name, value: null } }, true, false);
        } else {
          setFile(file);
        }

        setFileLoading(false);
      })();
    }
  }, []);

  const onDrop = async droppedFile => {
    setFileLoading(true);
    await handleUploadFilesToS3(
      droppedFile,
      externalUpload?.entityId || currentEntity._id,
      externalUpload?.accountId || activeAccount._id,
      externalUpload?.path || `user-uploads/${currentVersion._id}/${name}`
    );
    onChange({ target: { name, value: droppedFile[0].name } }, true, false);
    setFile(droppedFile[0]);
    setUploadError(false);
    setFileLoading(false);
  };

  const onDropRejected = rejectedFiles => {
    setUploadError(true);
    setFileLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
    onDropRejected,
    multiple: false,
    disabled: value,
  });

  const getFileType = fileName => fileName?.split('.').pop();

  const handleDeleteDocument = async () => {
    setFileLoading(true);
    await deleteDocumentFromS3ByKey(
      externalUpload
        ? `public/${externalUpload.accountId}/${externalUpload.entityId}/${externalUpload.path}/${value.replaceAll(' ', '_')}`
        : `public/${activeAccount._id}/${currentEntity._id}/user-uploads/${currentVersion._id}/${name}/${value.replaceAll(' ', '_')}`
    );
    setFile(null);
    await onChange({ target: { name, value: null } }, true, false);
    await setFileLoading(false);
  };

  const getItemIcon = type => {
    switch (type) {
      case 'docx':
        return <AiOutlineFileWord />;
      case 'xlsx':
        return <AiOutlineFileExcel />;
      case 'pdf':
        return <AiOutlineFilePdf />;
    }
  };

  return (
    <>
      <FieldContainer
        locked={locked}
        filled={isQuestionFilled()}
        subQuestion={isSubQuestion}
        disabled={disabled}
        last={last}
        span={span}
        display={display}
        className={className}>
        {label && (
          <InputLabel
            locked={locked}
            labelColor={labelColor}
            disabled={disabled}
            onMouseLeave={() => onLeave?.()}
            onMouseEnter={() => onHover?.()}
            dark={darkMode}
            // htmlFor={name}
            bold={bold}>
            {!locked && !disabled && status && <InputStatus filled={isQuestionFilled()}>{isQuestionFilled() && <MdCheck />}</InputStatus>}
            <span className={'label'}>{label}</span>
            {tooltip && <StyledHelpIcon sx={{ color: COLORS.Squash, fontSize: '20px', cursor: 'pointer', marginLeft: '10px' }} />}
            {required && !disabled && (
              <span className={'required'} style={{ color: 'red' }}>
                *
              </span>
            )}
            {!locked && !disabled && sharable && (
              <span ref={shareRef}>
                <RiShareForwardLine className={'share'} size={24} cursor={'pointer'} onClick={() => shareItem(id, label)} />
              </span>
            )}
          </InputLabel>
        )}
        {(type === 'text' || type === 'number') && (
          <div key={inputKey} className='input'>
            <InputWrapper icon={icon}>
              {icon && <IconContainer>{icon}</IconContainer>}
              <StyledInput
                ref={inputRef}
                autoFocus={focus}
                disabled={disabled || locked}
                disabledInput={disabled}
                placeholder={placeholder}
                onFocus={() => onHover?.()}
                onBlur={e => onBlur?.(e)}
                type='text'
                name={name}
                value={value ? handleTextInputValue(value) : ''}
                onChange={e => {
                  onChange({ target: { name, value: handleTextInputValue(e) } });
                }}
                onKeyDown={onKeyDown}
              />
            </InputWrapper>
          </div>
        )}
        {type === 'password' && (
          <PasswordContainer key={inputKey} className='input'>
            <StyledInput
              disabled={disabled || locked}
              disabledInput={disabled}
              placeholder={placeholder}
              type={passwordShown ? 'text' : 'password'}
              name={name}
              value={value}
              onChange={e => {
                onChange({ target: { name, value: e.target.value } });
              }}
              onKeyDown={onKeyDown}
            />
            <i onClick={toggleResetPassword}>{passwordShown ? <BiHide /> : <BiShow />}</i>
          </PasswordContainer>
        )}
        {type === 'radio' && (
          <React.Fragment key={inputKey}>
            <RadioContainer disabled={disabled || locked} dark={darkMode} className={longAnswers && 'long-answers'}>
              {options?.map((option, index) => (
                <React.Fragment key={index}>
                  <Option disabled={disabled || locked} locked={locked} dark={darkMode}>
                    <input
                      disabled={disabled || locked}
                      type='radio'
                      id={option.value}
                      value={option.value}
                      checked={option.value === value}
                      onChange={() => onChange({ target: { name, value: option.value } })}
                    />
                    <span>
                      {option.label}{' '}
                      <MdCancel
                        onClick={e => {
                          e.preventDefault();
                          onChange({ target: { name, value: null } });
                        }}
                      />
                    </span>
                  </Option>
                  {option.userInput && (
                    <div>
                      <input disabled={disabled || locked} type='text' id={option.value} name={name} />
                      <label htmlFor={name}>{option.placeholder}</label>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </RadioContainer>
          </React.Fragment>
        )}
        {type === 'select' && (
          <div className='input'>
            <Select
              menuPosition='fixed'
              placeholder={placeholder}
              isDisabled={disabled || locked}
              name={name}
              options={options}
              onChange={e => onChange(name, e?.value, e?.id, e)}
              defaultValue={defaultValue}
              value={value}
              isClearable={clearable === 'true'}
              onFocus={() => onHover?.()}
              onBlur={() => onLeave?.()}
              noOptionsMessage={noOptionsMessage}
            />
          </div>
        )}
        {type === 'checkbox' && (
          <div className='input'>
            {!locked && !disabled && (
              <Select
                isDisabled={disabled || locked}
                name={name}
                isMulti={true}
                options={options}
                defaultValue={getMultiDefaultValue(value) || []}
                onChange={e => onChange && onChange({ target: { name, value: getMultiValue(e) } })}
              />
            )}
            {(disabled || locked) && (
              <DisabledList>
                {getMultiDefaultValue(value).map((item, index) => (
                  <span key={index}>{item.label}</span>
                ))}
              </DisabledList>
            )}
          </div>
        )}
        {type === 'upload' && (
          <div className='input'>
            <UploadInputContainer type={getFileType(value)} filled={value} {...getRootProps()}>
              <input {...getInputProps()} />
              <span></span>
              <UploadContent>
                {!value && (
                  <span className={'infos'}>
                    <b>Cliquez</b> ici pour les sélectionner.
                  </span>
                )}
                {uploadError && (
                  <UploadError>
                    <div>Le format du fichier sélectionné est invalide.</div>
                    <div> Veuillez sélectionner un fichier au format word, excel ou pdf</div>
                  </UploadError>
                )}
                {value && file && (
                  <UploadItemContainer>
                    {getItemIcon(getFileType(value))}
                    <InfosContainer>
                      <UploadItemName>{value.replaceAll('_', ' ')}</UploadItemName>
                      <span className={'infos'}>( {Math.floor((file?.ContentLength || file?.size) / 1000)}Ko )</span>
                      <span className={'infos'}>Uploadé le {dayjs(file.lastUpdated).format('D MMMM YYYY')}</span>
                    </InfosContainer>
                  </UploadItemContainer>
                )}
              </UploadContent>

              {value ? (
                <DeleteDocument onClick={() => handleDeleteDocument()} ref={deleteFileRef}>
                  <AiOutlineDelete />
                </DeleteDocument>
              ) : (
                <span></span>
              )}

              {fileLoading && (
                <LoaderContainer>
                  <Loader loaderOnly={true} />
                </LoaderContainer>
              )}
            </UploadInputContainer>
          </div>
        )}
        {type === 'multiText' && (
          <MultiTextValueInput
            name={name}
            inputKey={inputKey}
            onMultiTextChange={onChange}
            placeholder={placeholder}
            defaultValue={getMultiDefaultValue(value) || []}
            onFocus={() => onHover?.()}
            onBlur={() => onLeave?.()}
          />
        )}
        {error && <Error errorColor={errorColor}>{error}</Error>}
      </FieldContainer>
    </>
  );
}
