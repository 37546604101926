import { lazy } from 'react';

import AdminPanel from 'src/components/admin-panel/AdminPanel';
import Collaborators from 'src/components/collaborators/Collaborators';
import DashboardEntity from 'src/components/dashboard-entity/DashboardEntity';
import DashboardGroup from 'src/components/dashboard-group/DashboardGroup';
import MyDocuments from 'src/components/my-documents/MyDocuments';
import Survey from 'src/components/survey/Survey';
import Profile from '@pages/profile/Profile';
import { paths } from './routes.constants';

const DashboardActions = lazy(() => import('@pages/ActionPlanDash/ActionPlanDash'));

// TODO: To be activated when admin pages are used
const AdminActionPlan = lazy(() => import('@pages/admin/ActionPlan/ActionPlan'));

export const routes = [
  {
    path: paths.admin,
    component: AdminPanel,
    private: true,
    exact: true,
  },
  {
    path: paths.dashboardGroup,
    component: DashboardGroup,
    private: true,
    exact: true,
  },
  {
    path: '/',
    component: DashboardGroup,
    private: true,
    exact: true,
  },
  {
    path: '/plan-action',
    component: DashboardActions,
    private: true,
    exact: false,
  },
  {
    path: paths.dashboardEntity,
    component: DashboardEntity,
    private: true,
    exact: true,
  },
  {
    path: paths.documents,
    component: MyDocuments,
    private: true,
    exact: true,
  },
  {
    path: paths.survey,
    component: Survey,
    private: true,
    exact: true,
  },
  {
    path: paths.collaborators,
    component: Collaborators,
    private: true,
    exact: true,
  },
  {
    path: paths.profile,
    component: Profile,
    private: true,
    exact: true,
  },
];

// Admin Pages
export const adminRoutes = [
  {
    path: paths.adminActionPlan,
    component: AdminActionPlan,
    private: true,
    exact: true,
  },
];
