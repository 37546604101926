import styled from "styled-components";
import {COLORS} from "@utils/constants";


export const ModalActions = styled.div`
  display: flex;
  column-gap: 20px;
  width: 100%;
  justify-content: flex-end;
`;

export const MenuSection = styled.div<{ expand?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  align-items: center;
  justify-content: flex-start;
  ${({ expand }) => expand && 'flex: 1;'}
`;

export const MenuSectionTitle = styled.div`
  font-family: 'Nunito', sans-serif;
  color: white;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 50px;
`;

export const MenuItem = styled.div<{ selected?: boolean; admin?: boolean }>`
  color: white;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  background-color: transparent;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #c8e3f6;
  }

  ${({ selected }) =>
    selected &&
    `
    color: ${COLORS.Black};
    background-color: #C8E3F6;
  `}
  span {
    margin-top: 3px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
  }
`;