import styled, { css } from 'styled-components';
import { COLORS, Z_INDEX } from '@utils/constants';

const EntityTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Empty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  align-self: center;

  svg {
    font-size: 80px;
    animation: swing 0.8s ease;
  }

  span {
    font-size: 1.2em;
  }
`;

const ContentContainer = styled.div<{ empty: boolean }>`
  display: grid;
  grid-template-columns: ${({ empty }) => (empty ? '1fr' : 'repeat(auto-fill, minmax(380px, 1fr))')};
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  ${({ empty }) =>
    empty &&
    css`
      flex: 1;
    `}
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
`;

const Tab = styled.div<{ selected: boolean; disabled: boolean }>`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? COLORS.softRed : 'white')};
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  border: ${COLORS.MediumGrey} solid 1px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.darkBlue};
    color: white;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.darkBlue};
      color: white;
    `}
`;

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;

  div {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${COLORS.lightGrey};
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

const DashboardEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 80px 50px 80px;
  height: calc(100% - 130px);
  width: calc(100% - 160px);
`;

const DashboardContent = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c8e3f6;
    opacity: 30%;
    z-index: ${Z_INDEX.below};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export { EntityTop, Empty, ContentContainer, TabContainer, Tab, ContentTitle, DashboardEntityContainer, DashboardContent, ButtonContainer };
