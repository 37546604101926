import { useEffect } from 'react';
import { Prompt, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccount } from 'src/reducers/account.reducer';
import { selectDirty, selectLoading, setCurrentEntity, setLoading } from 'src/reducers/app.reducer';
import { fetchAllContentfulData, selectAllContentfulData } from 'src/reducers/contentful.reducer';

import Loader from 'src/components/loader/loader';
import { SideNav } from 'src/components/side-nav/Sidenav';
import { paths } from 'src/routes/routes.constants';
import { UserComponent } from 'src/components/userComponent/UserComponent';

import { AppContainer, Content, ContentContainer, StyledToastContainer } from './mainLayout.styledComponents';

const MainLayout = ({ children }) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const location = useLocation();

  const contentfulContent = useSelector(selectAllContentfulData);

  const loading = useSelector(selectLoading);
  const account = useSelector(selectAccount);
  const surveyIsDirty = useSelector(selectDirty);

  useEffect(() => {
    if (surveyIsDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [surveyIsDirty]);

  useEffect(() => {
    (async () => {
      if (account) {
        const isContentfulDataLoaded = Object.values(contentfulContent).every(arr => Array.isArray(arr) && arr.length > 0);
        if (!isContentfulDataLoaded) {
          dispatch(setCurrentEntity(null));
          dispatch(setLoading(true));
          await dispatch(fetchAllContentfulData({}));
          dispatch(setLoading(false));
        }
      }
    })();
  }, []);

  if (!account) {
    if (location.pathname !== paths.admin) {
      localStorage.setItem('redirectUrl', location.search ? `${location.pathname}${location.search}` : location.pathname);
    }
    return <Redirect to={paths.login} />;
  }

  return (
    <AppContainer className='App'>
      {loading && <Loader />}
      {account && <SideNav />}
      {history.location.pathname !== paths.admin && history.location.pathname !== paths.wizard && !history.location.pathname.includes('/admin/') && (
        <UserComponent account={account} />
      )}
      <Content path={history.location.pathname}>
        <ContentContainer>{children}</ContentContainer>
      </Content>
      <Prompt when={false} message={'Vous avez des changements non sauvegardés. Êtes vous sûr de vouloir quitter la page?'} />
      <StyledToastContainer
        toastClassName='custom-toast-button'
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover={true}
        theme='colored'
      />
    </AppContainer>
  );
};

export default MainLayout;
