import styled from 'styled-components';
import { COLORS, Z_INDEX } from "@utils/constants";

const DashboardGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  row-gap: 25px;
  height: calc(100% - 80px);
  width: calc(100% - 200px);
 
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 40%;
    bottom: 0;
    background-color: #c8e3f6;
    opacity: 30%;
    z-index: ${Z_INDEX.below};
  }
`;

const DashboardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 3;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 30px;
  padding-bottom: 15px;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 50px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    color: ${COLORS.Black};

    svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`;

const Description = styled.div`
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    color: ${COLORS.Black};
  
  b {
    color: ${COLORS.darkBlue}
  }
`;

const DashboardContent = styled.div`
  flex: 1;
  min-height: 0;
  row-gap: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const Counters = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  overflow-y: auto;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export { DashboardGroupContainer, DashboardTop, Left, Right, Title, Description, DashboardContent, Counters, BasicModalContainer, ButtonContainer };
