import { Modal } from '../modal/Modal';
import {
  ButtonContainer,
  GlobalContainer,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionContent,
  Previous,
  StepsContainer,
  Step,
  StepLabel,
  StyledMdArrowForwardIos,
  EmptyStep,
  EmptyEntity,
} from './ModalUpdateSurveyRefs_.style';
import { Button } from '@components/Button';
import { InputComponent } from '@components/form/InputComponent';
import { useState } from 'react';
import { PiBriefcaseThin } from 'react-icons/pi';

export interface ModalRevertMigrationProps {
  show?: boolean;
  onClose: () => void;
  onUpdateSurveyRefs: (oldSurveyId: string, newSurveyId: string) => void;
  contentfulSurveys: any;
}

export const ModalUpdateSurveyRefs = ({
                                       show,
                                       onClose,
                                        onUpdateSurveyRefs,
                                       contentfulSurveys,
                                     }: ModalRevertMigrationProps) => {
  const [open, setOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [oldSurvey, setOldSurvey] = useState(null);
  const [newSurvey, setNewSurvey] = useState(null);

  const getSurveysOptions = () => {
    return contentfulSurveys.map(survey => ({ value: survey.id, label: survey.name })) || [];
  };

  const handlePreviousButton = () => {
    if (currentStep === 2) {
      setOldSurvey(null);
      setNewSurvey(null);
    }
    if (currentStep === 3) {
      setNewSurvey(null);
    }
    setCurrentStep(prevState => prevState - 1);
  };

  const handleOldSurvey = (e, surveyId) => {
    setCurrentStep(2);
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setOldSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleNewSurvey = (e, surveyId) => {
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setNewSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleUpdateSurveyRefs = () => {
    if(currentStep === 3) {
      onClose();
    }
    if (currentStep === 2) {
      onUpdateSurveyRefs(oldSurvey?.id, newSurvey?.id);
    }
    setCurrentStep(prevState => prevState + 1);
  };
  const getButtonLabel = () => {
    switch (currentStep) {
      case 1:
        return 'SUIVANT';
      case 2:
        return 'RESTAURER LA MIGRATION';
      case 3:
        return "J'AI COMPRIS";
    }
  };

  const isButtonDisabled = !oldSurvey?.id || !newSurvey?.id

  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: '75%', width: '40%' }} show={show} title={'Espace mise à jour des liens de questionnaires :'}>
          <GlobalContainer>
            {currentStep < 3 && (
              <DescriptionContainer>
                <DescriptionHeader onClick={() => setOpen(prevState => !prevState)}>
                  <b>Comment ça marche ?</b>
                  <StyledMdArrowForwardIos open={open} className={'arrow'} />
                </DescriptionHeader>
                <DescriptionContent open={open}>
                  {open && (
                    <>
                      <div>1. Veuillez sélectionner le questionnaire qui n'a plus de référence (ancien questionaire)</div>
                      <div>2. Sélectionnez ensuite le questionnaire référence (nouveau questionnaire)</div>
                      <div>3. Mettez à jour les liens (mise à jour des liens pour les actions + éléments partagés)</div>
                      <div>(n.b : La mise à jour des liens impactera l'ensemble des entités du compte)</div>
                    </>
                  )}
                </DescriptionContent>
              </DescriptionContainer>
            )}
            <StepsContainer>
              {currentStep < 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 1/3 :</b> Sélection de l'ancien questionnaire (qui n'a plus de référence) :
                  </StepLabel>
                  <InputComponent
                    type={'select'}
                    options={getSurveysOptions()}
                    onChange={handleOldSurvey}
                    sharable={false}
                    value={getSurveysOptions().filter(value => {
                      return value.value === oldSurvey?.id;
                    })}
                  />
                </Step>
              )}
              {currentStep < 3 && oldSurvey && (
                <Step>
                  <StepLabel>
                    <b>Etape 2/3 :</b> Sélection du nouveau questionnaire (questionnaire référence) :
                  </StepLabel>
                  {oldSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveysOptions()}
                      onChange={handleNewSurvey}
                      sharable={false}
                      value={getSurveysOptions().filter(value => {
                        return value.value === newSurvey?.id;
                      })}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire qui n'a plus de référence</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep === 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 3/3 :</b>  Changement de questionnaire référence effectué  !
                  </StepLabel>
                  <p>La màj des liens du questionnaire a été réalisée avec succès !</p>
                  <p>
                    Votre questionnaire "{oldSurvey.name}" a bien été remplacé dans l'ensemble des entités."
                  </p>
                  <p>
                    Le questionnaire "{newSurvey.name}" est désormais le nouveau questionnaire référence.
                  </p>
                </Step>
              )}
            </StepsContainer>
            <ButtonContainer>
              {currentStep > 1 && <Previous onClick={handlePreviousButton}>{'PRÉCÉDENT'}</Previous>}
              <Button onClick={handleUpdateSurveyRefs} label={getButtonLabel()} disabled={isButtonDisabled} customColor={'rgba(244, 157, 29, 1)'} />
            </ButtonContainer>
          </GlobalContainer>
        </Modal>
      )}
    </>
  );
};
