import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { selectLoading } from '../../reducers/app.reducer';
import { selectActiveAccount, selectImpersonated, selectUser, updateUser } from '../../reducers/account.reducer';

import { ConformityCard } from '../conformityCard/ConformityCard';
import { Button } from '../Button';
import { COLORS, USER_ROLES } from '../../utils/constants';
import { EntityTable } from '../entity-table/EntityTable';
import { Modal } from '../modal/Modal';
import Calendar from '../calendar/Calendar';

import { getAccountProductAmount, getCompletedEntityAmount } from '../../utils/utils';
import {
  DashboardGroupContainer,
  DashboardTop,
  Left,
  Right,
  Title,
  Description,
  BasicModalContainer,
  ButtonContainer,
} from '@components/dashboard-group/DashboardGroup_.style';
import { FiBookmark, FiCheckCircle, FiHome, FiUsers } from 'react-icons/fi';
import { getAccountConformityPercentage } from '@utils/api';

export interface DashboardGroupProps {}

const DashboardGroup = () => {
  //REDUX
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);
  const impersonated = useSelector(selectImpersonated);
  const currentUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);

  const userDetails = activeAccount?.users.find(user => user._id === currentUser._id);

  const [displayNewVersionModal, setDisplayNewVersionModal] = useState(userDetails?.displayNewVersionMsg);
  const [conformityPercentage, setConformityPercentage] = useState<number>(0);

  const displaySponsorship = () => {
    if (userDetails.role === USER_ROLES.Guest) {
      return false;
    }

    const currentDate = new Date().getTime();

    if (userDetails?.createdAt) {
      const createdAtDate = new Date(userDetails.createdAt).getTime();
      const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;

      if (currentDate - createdAtDate <= twoWeeksInMilliseconds) {
        return false;
      }
    }

    if (!userDetails?.lastSponsorshipDate) {
      return true;
    }

    const lastSponsorshipDate = new Date(userDetails.lastSponsorshipDate).getTime();
    const twoMonthsInMilliseconds = 2 * 30 * 24 * 60 * 60 * 1000;

    return currentDate - lastSponsorshipDate > twoMonthsInMilliseconds;
  };

  const [displaySponsorshipModal, setDisplaySponsorshipModal] = useState(userDetails !== undefined && displaySponsorship());

  useEffect(() => {
    userDetails !== undefined && setDisplaySponsorshipModal(displaySponsorship());
  }, [impersonated, userDetails]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCloseVersionMsgModal = async () => {
    const accountCopy = _.cloneDeep(activeAccount);
    let userCopy = accountCopy.users.find(userItem => userItem._id === currentUser._id);
    userCopy.displayNewVersionMsg = false;
    dispatch(updateUser({ userId: userCopy._id, data: userCopy }));
    setDisplayNewVersionModal(false);
  };

  const handleCloseSponsorshipModal = async () => {
    const accountCopy = _.cloneDeep(activeAccount);
    let userCopy = accountCopy.users.find(userItem => userItem._id === currentUser._id);
    userCopy.lastSponsorshipDate = new Date();
    dispatch(updateUser({ userId: userCopy._id, data: userCopy }));
    setDisplaySponsorshipModal(false);
  };

  const groupCardData = {
    title: `${t('dashboard.complianceLevel')} ${activeAccount?.companyName}`,
    data: [
      {
        icon: FiHome,
        label: `${activeAccount?.entities?.length} ${activeAccount?.entities?.length > 1 ? t('common.entities') : t('common.entity')}`,
        color: COLORS.darkBlue,
      },
      {
        icon: FiUsers,
        label: `${activeAccount?.users?.length} ${activeAccount?.users?.length > 1 ? t('common.collaborators') : t('common.collaborater')}`,
        color: COLORS.darkBlue,
      },
      {
        icon: FiBookmark,
        label: `${getAccountProductAmount(activeAccount)} ${t('common.product')}${getAccountProductAmount(activeAccount) > 1 ? 's' : ''}`,
        color: COLORS.darkBlue,
      },
      {
        icon: FiCheckCircle,
        label: `${getCompletedEntityAmount(activeAccount)} ${
          getCompletedEntityAmount(activeAccount) > 1 ? t('common.entities') : t('common.entity')
        } ${t('common.compliant')}${getCompletedEntityAmount(activeAccount) > 1 ? 's' : ''}`,
        color: COLORS.darkBlue,
      },
    ],
    percentage: conformityPercentage,
  };

  const handleMeetingDPO = () => {
    setIsCalendarOpen(prevState => !prevState);
  };

  useEffect(() => {
    (async () => {
      let result = await getAccountConformityPercentage(activeAccount._id);
      setConformityPercentage(Number(result));
    })();
  }, []);

  return (
    <DashboardGroupContainer>
      <DashboardTop>
        <Left>
          <Title>
            <span>{activeAccount?.companyName}</span>
          </Title>
          <Description>
            {t('dashboard.welcome_start')}
            <b>{currentUser?.firstName}</b>
            {t('dashboard.welcome_end')}
          </Description>
          {currentUser?.role !== USER_ROLES.Guest && (
            <Button
              shadowOptions={{ active: true }}
              labelOptions={{ fontWeight: '900' }}
              borderOptions={{ width: '2' }}
              type={'bright'}
              onClick={handleMeetingDPO}
              label={t('dashboard.appointment')}
            />
          )}
        </Left>
        <Right></Right>
      </DashboardTop>
      <ConformityCard {...groupCardData} />
      <EntityTable />
      {!loading && !impersonated && (
        <Modal
          alignment={'center'}
          size={{ width: '50%' }}
          show={displayNewVersionModal}
          title={`${t('dashboard.refonte')} 🎉`}
          onClose={handleCloseVersionMsgModal}
          overAll>
          <BasicModalContainer
            dangerouslySetInnerHTML={{
              __html: t('dashboard.refonteText'),
            }}></BasicModalContainer>
        </Modal>
      )}

      {!loading && !impersonated && displaySponsorshipModal && (
        <Modal
          alignment={'center'}
          size={{ width: '50%' }}
          show={displaySponsorshipModal}
          title={`${t('dashboard.sponsorship')} 🎉`}
          onClose={handleCloseSponsorshipModal}
          overAll>
          <BasicModalContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: t('dashboard.sponsorshipText'),
              }}></div>
            <ButtonContainer>
              <Button label={t('common.seeMore')} onClick={() => window.open(process.env.REACT_APP_SPONSORSHIP_LINK, '_blank')}></Button>
            </ButtonContainer>
          </BasicModalContainer>
        </Modal>
      )}

      {isCalendarOpen && <Calendar isCalendarOpen={isCalendarOpen} setIsCalendarOpen={setIsCalendarOpen} activeAccount={activeAccount} />}
    </DashboardGroupContainer>
  );
};

export default DashboardGroup;
