import styled from 'styled-components';

export const Container = styled.div<{
  width?: string;
  height?: string;
  collapsible?: boolean;
  barOpened?: boolean;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  padding: 8px;
  border-radius: 8px; 
  transition:
    width 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    border 0.2s ease-in-out;

  ${({ collapsible, barOpened, width }) => {
    if (collapsible) {
      return `
        width: ${barOpened ? '25rem' : '1.5rem'};
        cursor: ${barOpened ? 'auto' : 'pointer'};
      `;
    } else {
      return `
        ${barOpened ? 'border: 1px solid #3AA3CC;' : 'border: 1px solid #C4C3C2'};
        ${width ? `width: ${width};` : 'width: 25rem;'}
      `;
    }
  }}
  opacity: ${({ barOpened }) => (barOpened ? '1' : '0.8')};
`;

export const IconContainer = styled.div<{ collapsible?: boolean; barOpened?: boolean }>`
  line-height: 1;
  pointer-events: ${({ collapsible, barOpened }) => (collapsible && barOpened ? 'auto' : 'none')};
  cursor: ${({ collapsible, barOpened }) => (collapsible && barOpened ? 'pointer' : 'none')};
  background-color: transparent;
  border: none;
  outline: none;
  color: white;

  svg {
    color: black;
    font-size: 1.5rem;
  }
`;

export const InputContainer = styled.div<{ collapsible?: boolean; barOpened?: boolean }>`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;

  svg {
    color: black;
    cursor: pointer;
    font-size: 1.5rem;

    ${({ collapsible, barOpened }) => {
      if (collapsible) {
        return `
          display: ${barOpened ? 'block' : 'none'};
        `;
      } else {
        return `
          display: block;
        `;
      }
    }}
  }
`;

export const Input = styled.input<{ collapsible?: boolean; barOpened?: boolean }>`
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  width: 100%;
  border: none;
  color: black;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: grey;
  }

  ${({ collapsible, barOpened }) => {
    if (collapsible) {
      return `
          margin-left: ${barOpened ? '1rem' : ''};
        `;
    } else {
      return `
          margin-left: 1rem;
        `;
    }
  }}
`;
