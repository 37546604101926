import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSidenavState } from '../../reducers/app.reducer';
import { getAccountById } from '../../utils/api';
import {
  setImpersonated,
  getAllAccounts,
  selectAllAccounts,
  setSelectedAccount,
  selectUser,
  selectImpersonated,
  selectAccount,
} from '../../reducers/account.reducer';
import { useEffect, useState } from 'react';
import { paths } from '@routes/routes.constants';
import { useHistory } from 'react-router-dom';
import Loader from '../loader/loader';
import {
  Badge,
  ListSlider,
  List,
  LoaderContainer,
  User,
  UserListContainer,
  SearchContainer,
  FilterContainer,
  ListTitle,
  Name,
} from './UserList_.style';
import { COLORS } from '../../utils/constants';
import { FaRegUser } from 'react-icons/fa';

export const UserList = () => {
  const allAccounts = useSelector(selectAllAccounts);
  const connectedAccount = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const impersonated = useSelector(selectImpersonated);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [search, setSearch] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [userLists, setUserLists] = useState([]);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearch(value === '' ? null : value);
  };

  const getFilteredAccounts = accounts => {
    let searchAccounts = !isSearchedString()
      ? accounts
      : accounts.filter(
          account =>
            account?.companyName
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              ?.includes(
                search
                  ?.toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              ) || account?.users?.some(user => user?.email?.toLowerCase().includes(search?.toLowerCase()))
        );
    if (filters.includes('my-customers')) {
      searchAccounts = searchAccounts.filter(account => account.legalOfficer?.email === user.email);
    }
    if (filters.includes('to-be-validated')) {
      searchAccounts = searchAccounts.filter(account => account.isAnySubmittedEntity);
    }

    return searchAccounts;
  };

  const handleSelectAccount = async account => {
    dispatch(setSidenavState(false));
    dispatch(setLoading(true));
    const accountRefreshedData = await getAccountById(account._id);
    dispatch(setImpersonated(null));
    dispatch(setSelectedAccount(accountRefreshedData));
    if (history.location.pathname !== paths.admin) {
      history.push(paths.admin);
    }
    dispatch(setLoading(false));
  };

  const isSearchedString = () => search !== null && search !== '';

  const getBadge = account => {
    let label = '';
    let color = null;

    if (account.isAnySubmittedEntity) {
      label = 'À valider';
    }
    if (account._id === connectedAccount._id) {
      label = 'Mon compte';
      color = COLORS.DarkGreen;
    }
    if (account._id === impersonated?._id) {
      label = 'Impersonated';
      color = COLORS.admin;
    }
    if (
      (account.accountType === 'user' || account.accountType === 'superadmin') &&
      !account.isAnySubmittedEntity &&
      account._id !== impersonated?._id &&
      account._id !== connectedAccount._id
    ) {
      return '';
    }

    return <Badge color={color}>{label}</Badge>;
  };

  const getUserIcon = account => {
    let options = { color: COLORS.Black };
    if (account.accountType === 'superadmin') {
      options.color = COLORS.MediumGrey;
    }
    if (account.isAnySubmittedEntity) {
      options.color = COLORS.Squash;
    }
    if (account._id === connectedAccount._id) {
      options.color = COLORS.DarkGreen;
    }
    if (account._id === impersonated?._id) {
      options.color = COLORS.admin;
    }
    return <FaRegUser {...options} />;
  };

  const getUserColor = account => {
    let color = null;
    if (account.accountType === 'superadmin') {
      color = COLORS.MediumGrey;
    }
    if (account.isAnySubmittedEntity) {
      color = COLORS.Squash;
    }
    if (account._id === connectedAccount._id) {
      color = COLORS.DarkGreen;
    }
    if (account._id === impersonated?._id) {
      color = COLORS.admin;
    }
    return color;
  };

  const handleFilterAccounts = filter => {
    if (filters.includes(filter)) {
      setFilters(filters.filter(f => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  };

  const isFilterActive = filter => filters.includes(filter);

  const initAccountLists = () => {
    let userAccounts = allAccounts.filter(account => account.isArchived !== true && (account.accountType === 'user' || account.accountType === 'agency'));
    let adminAccounts = allAccounts.filter(account => account.isArchived !== true && account.accountType === 'superadmin');
    let archivedAccounts = allAccounts.filter(account => account.isArchived === true);
    setUserLists([
      { title: 'Clients', list: userAccounts },
      { title: 'Juristes & Admins', list: adminAccounts },
      { title: 'Archivés', list: archivedAccounts },
    ]);
  };

  const renderUserList = (accounts, index) => {
    let filteredAccounts = getFilteredAccounts(accounts.list);

    if (filteredAccounts.length === 0) return null;
    return (
      <List key={'account-' + index}>
        <ListTitle>
          {accounts.title} <span>( {filteredAccounts.length} )</span>
        </ListTitle>
        {filteredAccounts.map((account, index) => (
          <User color={getUserColor(account)} key={accounts.title + index} onClick={() => handleSelectAccount(account)}>
            <span>
              {getUserIcon(account)}
              <Name>{account.companyName}</Name>
            </span>
            {getBadge(account)}
          </User>
        ))}
      </List>
    );
  };

  useEffect(() => {
    (async () => {
      setListLoading(true);
      await dispatch(getAllAccounts());
      setListLoading(false);
    })();
  }, []);

  useEffect(() => {
    initAccountLists();
  }, [allAccounts]);

  return (
    <UserListContainer>
      <FilterContainer>
        <span>Filtres : </span>
        <Badge onClick={() => handleFilterAccounts('my-customers')} selected={isFilterActive('my-customers')} hover={true}>
          <span>Mes clients</span>
        </Badge>
        <Badge onClick={() => handleFilterAccounts('to-be-validated')} selected={isFilterActive('to-be-validated')} hover={true}>
          <span>A valider</span>
        </Badge>
      </FilterContainer>
      <SearchContainer>
        <input onChange={handleSearchChange} placeholder={`Rechercher un client (Nom, Email)`} type='text' />
      </SearchContainer>
      {listLoading && (
        <LoaderContainer>
          <Loader loaderOnly={true} />
        </LoaderContainer>
      )}
      {!listLoading && (
        <>
          <ListSlider>{userLists.map(renderUserList)}</ListSlider>
        </>
      )}
    </UserListContainer>
  );
};
