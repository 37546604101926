import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { tippy } from '@tippyjs/react';

import { FaTasks } from 'react-icons/fa';
import { BiHome } from 'react-icons/bi';
import { MdAdminPanelSettings, MdOutlineBusinessCenter, MdOutlinePeopleAlt } from 'react-icons/md';
import { HiDocumentArrowDown } from 'react-icons/hi2';
import { BsBug, BsCheckCircle } from 'react-icons/bs';
import { VscDebugConsole } from 'react-icons/vsc';

import {
  selectCurrentEntity,
  selectCurrentSurvey,
  selectCurrentVersion,
  selectDirty,
  selectDisplayDebug,
  selectDisplayShareModal,
  selectSelectedCollaborator,
  setDirty,
  setDisplayDebug,
  setModal,
  setSidenavState,
  selectAdminStepsSelectedEntity,
  setCurrentEntity,
  setCurrentSurvey,
} from '../../reducers/app.reducer';
import {
  selectAccount,
  selectActiveAccount,
  selectImpersonated,
  selectOriginalUser,
  selectSelectedAccount,
  selectUser,
  setSelectedAccount,
} from '../../reducers/account.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';

import { Modal } from '../modal/Modal';
import { Button } from '../Button';
import { ModalActions, MenuItem, MenuSection, MenuSectionTitle, MenuContainer } from './Menu_.style';

import { ADMIN_ROLES, MODAL_TYPES } from '../../utils/constants';
import { paths } from '@routes/routes.constants';
import { useTranslation } from 'react-i18next';
import CheckList from '@components-new/molecules/checkList/CheckList';
import { getAuthorizedSurveys, getAuthorizedSurveysByEntity, getSurveysCompletionPercentageByAccount } from '@utils/utils';

export interface dirtyModalInterface {
  show: boolean;
  options: {
    actions: Array<any>;
  };
}

export const Menu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const homeRef = useRef(null);
  const collabRef = useRef(null);
  const driveRef = useRef(null);
  const docRef = useRef(null);
  const actionsRef = useRef(null);
  const debugModeRef = useRef(null);
  const debugConsoleRef = useRef(null);
  const entityRef = useRef(null);
  const checkListRef = useRef(null);
  const contentfulData = useSelector(selectAllContentfulData);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentVersion = useSelector(selectCurrentVersion);
  const currentEntity = useSelector(selectCurrentEntity);
  const selectedAdminStepEntity = useSelector(selectAdminStepsSelectedEntity);
  const displayShareModal = useSelector(selectDisplayShareModal);
  const account = useSelector(selectAccount);
  const activeAccount = useSelector(selectActiveAccount);
  const displayDebug = useSelector(selectDisplayDebug);
  const impersonated = useSelector(selectImpersonated);
  const currentUser = useSelector(selectUser);
  const selectedCollaborator = useSelector(selectSelectedCollaborator);
  const selectedAccount = useSelector(selectSelectedAccount);
  const isDirty = useSelector(selectDirty);
  const [dirtyModal, setDirtyModal] = useState({
    show: false,
    actions: [],
  });
  const originalUser = useSelector(selectOriginalUser);

  let showActionPlan: boolean;

  if (currentUser?.role !== 'guest') {
    showActionPlan = activeAccount?.entities?.some(entity => entity?.actionPlan?.actions?.some(action => action?.active));
  } else {
    showActionPlan = activeAccount?.entities?.some(entity => entity?.actionPlan?.actions?.some(action => action?.active && action?.responsible.includes(currentUser._id)));
  }

  const preventDefault = e => e.preventDefault();

  const print = () => {
    console.log({
      contentfulData,
      currentEntity,
      currentSurvey,
      currentVersion,
      account,
      impersonated,
      displayShareModal,
      activeAccount,
      currentUser,
      selectedCollaborator,
      selectedAccount,
      selectedAdminStepEntity,
      originalUser,
    });
  };

  useEffect(() => {
    tippy(homeRef.current, {
      content: t('menu.dashboard'),
      arrow: true,
      placement: 'right',
    });
    tippy(driveRef.current, {
      content: t('menu.google_drive'),
      arrow: true,
      placement: 'right',
    });
    showActionPlan &&
      tippy(actionsRef.current, {
        content: t('menu.action_plan'),
        arrow: true,
        placement: 'right',
      });
    tippy(debugConsoleRef.current, {
      content: t('menu.print_console'),
      arrow: true,
      placement: 'right',
    });
    tippy(debugModeRef.current, {
      content: t('menu.debug_mode'),
      arrow: true,
      placement: 'right',
    });
    tippy(docRef.current, {
      content: t('common.documents'),
      arrow: true,
      placement: 'right',
    });
    tippy(collabRef.current, {
      content: t('common.collaborators'),
      arrow: true,
      placement: 'right',
    });
    tippy(entityRef.current, {
      content: t('common.entities'),
      arrow: true,
      placement: 'right',
    });
    tippy(checkListRef.current, {
      content: t('common.help'),
      arrow: true,
      placement: 'right',
    });
  }, []);

  const handleMouseLeave = () => {
    dispatch(setSidenavState(null));
  };

  const handleOpenMenu = options => {
    dispatch(setDirty(false));
    dispatch(
      setSidenavState({
        open: true,
        type: options.type,
        level: options.level,
      })
    );
  };

  const handleChangePage = (targetPath, closePanel) => {
    dispatch(setDirty(false));
    dispatch(setModal({ show: false, type: null }));
    if (targetPath) {
      history.push(targetPath);
    }
    if (closePanel) {
      handleMouseLeave();
    }
  };

  const closeDirtyModal = () => {
    setDirtyModal({ show: false, actions: [] });
  };

  const handleMenuClick = (type, options) => {
    if (options.type === 'admin' && account?.accountType === 'agency') {
      dispatch(setSelectedAccount(account));
      history.push(paths.admin);
      return;
    }
    if (isDirty) {
      setDirtyModal({
        show: true,
        actions: [
          {
            buttonType: 'danger',
            label: t('common.continue_without_saving'),
            onClick: () => {
              closeDirtyModal();
              if (type === 'page') {
                handleChangePage(options.targetPath, true);
              } else {
                handleOpenMenu({ type: options.type, level: options.level });
              }
            },
          },
          {
            label: t('common.cancel'),
            onClick: closeDirtyModal,
            buttonType: 'secondary',
          },
        ],
      });
    } else {
      if (type === 'page') {
        handleChangePage(options.targetPath, true);
      } else {
        handleOpenMenu({ type: options.type, level: options.level });
      }
    }
  };

  const hasActiveProducts = () => {
    return activeAccount.entities.some(entity => {
      return !!entity?.responses?.filter(
        response =>
          response?.versions?.length &&
          getAuthorizedSurveysByEntity(entity)
            ?.filter(survey => survey.versioning.enabled)
            ?.some(survey => survey.id === response?.surveyId)
      ).length;
    });
  };

  const showModalSubmitEntity = entity => {
    dispatch(setCurrentEntity(entity));
    dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION }));
  };

  const getSubmittedEntity = () => {
    let submittedEntity = activeAccount.entities.map(entity => {
      return {
        title: entity.name,
        onClick: () => showModalSubmitEntity(entity),
        checked: entity.submission?.submitted || entity.submission?.validated,
        disabled: false,
      };
    });

    return submittedEntity;
  };

  const actionsCompleted = () => {
    for (const entity of activeAccount.entities) {
      for (const action of entity.actionPlan.actions) {
        if (action.status !== 'completed' && action.active) {
          return false;
        }
      }
    }
    return true;
  };

  const handleEntityClick = async () => {
    dispatch(setCurrentEntity(activeAccount.entities[0]));
    let authorizedSurveys = getAuthorizedSurveys();
    dispatch(setCurrentSurvey(authorizedSurveys?.[0]));
    history.push(paths.dashboardEntity);
  };

  const hasAllSurveysCompleted = () => {
    const percentage = getSurveysCompletionPercentageByAccount(activeAccount);
    return percentage >= 100;
  };

  const hasAllEntitiesSubmitted = () => {
    return getSubmittedEntity().length === activeAccount.entities.length;
  };

  const getCompletedSurveyVersionsToBeSubmitted = () => {
    let result = [];
    let unvalidatedEntitiesToBeSubmitted = activeAccount.entities
      .filter(entity => !entity.submission.validated)
      .filter(entity => !entity.submission?.submitted);
    for (let entity of unvalidatedEntitiesToBeSubmitted) {
      let enabledSurveys = entity.responses.filter(survey => !survey.disabled);
      for (let survey of enabledSurveys) {
        if (survey.versions) {
          for (let version of survey.versions) {
            if (version.completion?.completionPercentage === 100) {
              result.push(version);
            }
          }
        }
      }
    }
    return result;
  };

  const openCheckList = () => {
    if (getCompletedSurveyVersionsToBeSubmitted()?.length !== 0) {
      return true;
    }
    return !localStorage.getItem('lastLogin');
  };

  return (
    <>
      <MenuContainer>
        <MenuSection expand={true}>
          <MenuItem
            ref={homeRef}
            onClick={() => handleMenuClick('page', { targetPath: paths.dashboardGroup, closePanel: true })}
            selected={history.location.pathname === paths.dashboardGroup}>
            <BiHome />
          </MenuItem>
          <MenuItem
            ref={entityRef}
            onClick={() => handleMenuClick('menu', { type: 'entities', level: '1' })}
            admin={false}
            selected={history.location.pathname === paths.dashboardEntity || history.location.pathname === paths.survey}>
            <MdOutlineBusinessCenter />
          </MenuItem>

          {currentUser.role !== 'guest' && (
            <>
              <MenuItem
                ref={collabRef}
                admin={false}
                onClick={() => handleMenuClick('menu', { type: 'collaborators', level: '1' })}
                selected={history.location.pathname === paths.collaborators}>
                <MdOutlinePeopleAlt />
              </MenuItem>
              <MenuItem
                ref={docRef}
                onClick={() => handleMenuClick('page', { targetPath: paths.documents, closePanel: true })}
                selected={history.location.pathname === paths.documents}>
                <HiDocumentArrowDown />
              </MenuItem>
            </>
          )}
          {showActionPlan && (
            <MenuItem
              ref={actionsRef}
              admin={false}
              onClick={() => handleMenuClick('page', { targetPath: paths.userAction, closePanel: true })}
              selected={history.location.pathname.includes(paths.userAction)}>
              <FaTasks />
            </MenuItem>
          )}
        </MenuSection>
        {(activeAccount?.accountType === 'superadmin' || activeAccount?.accountType === 'agency' || impersonated) &&
          currentUser?.role !== 'guest' && (
            <MenuSection>
              <MenuSectionTitle>{t('menu.admin')}</MenuSectionTitle>
              <MenuItem
                onClick={() => handleMenuClick('menu', { type: 'admin', level: '1' })}
                onMouseOver={preventDefault}
                onMouseOut={preventDefault}
                admin={true}
                selected={history.location.pathname === paths.admin}>
                <MdAdminPanelSettings />
              </MenuItem>
              {account?.accountType === 'superadmin' && (
                <>
                  <MenuItem ref={debugModeRef} onClick={() => dispatch(setDisplayDebug(!displayDebug))} selected={displayDebug}>
                    <BsBug />
                  </MenuItem>
                  <MenuItem ref={debugConsoleRef} onClick={print} onMouseEnter={handleMouseLeave}>
                    <VscDebugConsole />
                  </MenuItem>
                </>
              )}
            </MenuSection>
          )}
        {ADMIN_ROLES.includes(currentUser.role) && (
          <MenuItem ref={checkListRef} onMouseEnter={handleMouseLeave} onMouseOver={preventDefault}>
            <CheckList
              title={t('checkList.title')}
              subTitle={t('checkList.subTitle')}
              actionList={[
                {
                  id: uuidv4(),
                  title: t('checkList.createEntity'),
                  onClick: () => dispatch(setModal({ show: true, type: MODAL_TYPES.ADD_NEW_ENTITY })),
                  checked: activeAccount.entities.length > 0,
                  disabled: false,
                },
                {
                  id: uuidv4(),
                  title: t('checkList.createProduct'),
                  onClick: () => handleEntityClick(),
                  checked: hasActiveProducts(),
                  disabled: activeAccount?.entities?.length === 0,
                },
                {
                  id: uuidv4(),
                  title: t('checkList.completeSurvey'),
                  onClick: () => handleEntityClick(),
                  checked: hasAllSurveysCompleted() || hasAllEntitiesSubmitted(),
                  disabled: activeAccount?.entities?.length === 0,
                },
                {
                  id: uuidv4(),
                  title: t('checkList.submitEntity'),
                  disabled: activeAccount?.entities?.length === 0,
                  subMenu: getSubmittedEntity(),
                },
                {
                  id: uuidv4(),
                  title: t('checkList.actionPlan'),
                  onClick: () => history.push(paths.userAction),
                  checked: actionsCompleted(),
                  disabled: !showActionPlan,
                },
              ]}
              icon={<BsCheckCircle />}
              open={openCheckList()}
            />
          </MenuItem>
        )}
      </MenuContainer>
      <Modal show={dirtyModal.show} onClose={closeDirtyModal} title={t('menu.unsaved_changes')}>
        <span>{t('menu.unsaved_changes_message')}</span>
        <ModalActions>
          {dirtyModal.actions.map((action, index) => (
            <Button label={action.label} key={index} onClick={action.onClick} type={action.buttonType} />
          ))}
        </ModalActions>
      </Modal>
    </>
  );
};
