import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FaRegTimesCircle } from 'react-icons/fa';

import {
  selectImpersonated,
  setImpersonated,
  selectUser,
  setSelectedAccount,
  selectOriginalUser,
  setUser,
  setOriginalUser,
} from '../../reducers/account.reducer';
import { setCurrentEntity, selectDirty } from '../../reducers/app.reducer';

import { paths } from '@routes/routes.constants';

import { Initial } from '../initials/Initial';
import { Notifications } from '../notifications/Notifications';
import DropDownMenu from '../dropDownMenu/DropDownMenu';

import { getNotifications, logout } from '@utils/api';
import { USER_ROLES } from '@utils/constants';
import { addEventTracking, disconnect } from '@utils/utils';

import { UserComponentContainer, UserName, UserNameContainer } from './UserComponent.styles';

export interface AccountComponentProps {
  account: any;
}

export const UserComponent = ({ account }: AccountComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const impersonated = useSelector(selectImpersonated);
  const user = useSelector(selectUser);
  const originalUser = useSelector(selectOriginalUser);
  const selectedUser = useMemo(
    () => (originalUser !== undefined ? user : impersonated?.users?.find(user => user.role === USER_ROLES.AccountOfficer) ?? user),
    [impersonated, user]
  );
  const surveyIsDirty = useSelector(selectDirty);
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const notificationList = await getNotifications(impersonated?._id ?? account._id, selectedUser._id);
      setNotifications(notificationList);
    };

    selectedUser && fetchNotifications();
  }, [selectedUser._id]);

  const handleDisconnectUser = async () => {
    let leaveAnyway = true;
    if (surveyIsDirty) {
      leaveAnyway = window.confirm('Vous avez des changements non sauvegardés. Etes vous sûr de vouloir quitter la page?');
    }
    if (leaveAnyway) {
      await logout();
      disconnect()
      history.push(paths.login);
    }
  };

  const handleRemoveImpersonated = () => {
    dispatch(setImpersonated(null));
    dispatch(setSelectedAccount(null));
    dispatch(setCurrentEntity(account.entities[0]));
    if (originalUser) {
      dispatch(setUser(originalUser));
      dispatch(setOriginalUser(undefined));
    }
    history.push(paths.dashboardGroup);
  };

  const getInitialOptions = () => [
    {
      label: t('common.profile'),
      onClick: () => {
        addEventTracking('Profile', 'Click');
        history.push(paths.profile);
      },
    },
    {
      label: t('common.FAQ'),
      onClick: () => {
        addEventTracking('FAQ', 'Click');
        window.open(process.env.REACT_APP_FAQ_LINK, '_blank');
      },
    },
    {
      label: t('common.sponsorship'),
      onClick: () => {
        addEventTracking('Sponsorship', 'Click');
        window.open(process.env.REACT_APP_SPONSORSHIP_LINK, '_blank');
      },
    },
    {
      label: t('common.logout'),
      onClick: handleDisconnectUser,
    },
  ];

  return (
    <UserComponentContainer>
      <UserName>
        {impersonated && (
          <span>
            ( Connecté en tant que <b>{selectedUser?.firstName ? selectedUser?.name + ' ' + selectedUser?.firstName : selectedUser?.name}</b> pour
            l'entreprise <b>{impersonated.companyName}</b>
            <FaRegTimesCircle onClick={handleRemoveImpersonated} />)
          </span>
        )}
      </UserName>

      <Notifications notificationsList={notifications} />
      {history.location.pathname !== paths.admin && (
        <DropDownMenu
          title={
            <UserNameContainer>
              {selectedUser?.name?.toUpperCase()} {selectedUser?.firstName?.toUpperCase()}
            </UserNameContainer>
          }
          width={'300px'}
          options={getInitialOptions()}
          header={<Initial name={selectedUser?.name || user?.email} firstName={selectedUser?.firstName} userId={selectedUser?._id} />}
        />
      )}
    </UserComponentContainer>
  );
};
