import { COLORS } from '../../utils/constants';
import { useEffect, useState } from 'react';

export interface PieProps {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  rounded?: boolean;
  colors?: {
    bg?: string;
    fg?: string;
  };
  fontSize?: string;
  duration?: number;
}

export const Pie = ({ percentage, radius, strokeWidth, rounded, colors, fontSize, duration }: PieProps) => {
  const [progress, setProgress] = useState(0);
  radius = radius ?? 25;

  useEffect(() => {
    setProgress(percentage);
  }, [progress, percentage]);

  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      {/* Cercle de fond */}
      <circle stroke={colors?.bg ?? '#DDF1F9'} fill='transparent' strokeWidth={strokeWidth} r={normalizedRadius} cx={radius} cy={radius} />
      {/* Cercle de progression */}
      <circle
        stroke={colors?.fg ?? COLORS.darkBlue}
        fill='transparent'
        strokeWidth={strokeWidth ?? '10'}
        strokeDasharray={circumference + ' ' + circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap={rounded ? 'round' : 'butt'}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          transition: `stroke-dashoffset ${duration ?? '0.3'}s`,
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        dy='.3em'
        fontSize='20px'
        style={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: fontSize ?? '12px' }}>
        {`${progress}%`}
      </text>
    </svg>
  );
};
